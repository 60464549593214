import React, { Fragment, useEffect, useState } from "react";
import styled from "styled-components";
import BetsService from "../../services/bets";
import LoadingIndicator from "../Shared/LoadingIndicator";

const GenericLiveOddsLeaderboardNew = ({ provider, limit, random, fullWidth, noNames, reverse }) => {
  const [betData, setBetData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const placeholderLogo =
    "https://static.draft5.gg/assets/team-logo-placeholder.png";

  useEffect(() => {
    trackWidgetImpression();

    BetsService.getLiveOddsWithGameKey(
      provider.PROVIDER_KEY,
      "draft5csgo"
    ).then(
      (response) => {
        {
          !random && setBetData(response.data.data.slice(0, limit));
        }
        {
          !!random &&
            setBetData(
              response.data.data.sort(() => Math.random() - 0.5).slice(0, limit)
            );
        }
        setIsLoading(false);
      },
      () => {
        setError(true);
        setIsLoading(false);
      }
    );
  }, []);

  const trackWidgetImpression = () => {
    window.dataLayer.push({
      event: "LiveOdds728Impression",
      liveOddsProvider: provider.PROVIDER_KEY,
    });
  };

  const trackWidgetClick = () => {
    window.dataLayer.push({
      event: "LiveOdds728Click",
      liveOddsProvider: provider.PROVIDER_KEY,
    });
  };

  return (
    <LiveOddsContainer provider={provider} fullWidth={fullWidth} >
      <LogoContainer>
      <ProviderLogo
        onClick={trackWidgetClick}
        href={provider.PROVIDER_URL}
        // href="https://mercuryeasy.com/gg/gb/?param=draft5_3&lp=00&go=sitereg&deeplink=/news/welcome-bonus-2023"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img src={provider.PROVIDER_LOGO} />
        <Disclaimer provider={provider}>{provider.PROVIDER_WARNING}</Disclaimer>
      </ProviderLogo>
      </LogoContainer>
      <OddsContainer>
        {/* ========================== */}
      {/* LOADING ================== */}
      {/* ========================== */}
      {/* {isLoading && <LoadingIndicator />} */}

{/* ========================== */}
{/* EMPTY STATE ============== */}
{/* ========================== */}
{!isLoading && betData.length === 0 && (
  <LiveOddMatch provider={provider}>
    <span>Nenhuma partida encontrada.</span>
  </LiveOddMatch>
)}

{/* ========================== */}
{/* ERROR STATE ============== */}
{/* ========================== */}
{/* {!isLoading && error && (
  <LiveOddMatch provider={provider}>
    <span>Ocorreu um erro, tente novamente.</span>
  </LiveOddMatch>
)} */}


{/* <TitleOdd provider={provider}>Quem irá vencer?</TitleOdd>
<LineBreak /> */}

{!isLoading &&
        betData.length > 0 &&
        betData.map((match) => (
          <>
          <LiveOddMatch
            // href={match.teamA?.link}
            href={provider.PROVIDER_URL}
            target="_blank"
            rel="noopener noreferrer"
            title={`${match.teamA.name} vs. ${match.teamB.name} - ${match.tournamentName}`}
            provider={provider}
            fullWidth={fullWidth}
            reverse={reverse}
            key={`${match.teamA.name}-${match.teamB.name}-${match.matchId}`}
            onClick={() => {
              trackWidgetClick(
                `${match.matchId} - ${match.teamA.name} vs ${match.teamB.name}`
              );
            }}
          >
            <TeamName provider={provider} noNames={noNames}>{match.teamA.name.split(" ")[0]}</TeamName>
            <img
              src={`https://${match.teamA?.logo}`}
              width="30"
              alt={match.teamA.name}
              title={match.teamA.name}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = placeholderLogo;
              }}
            />
            {match.teamA.odd && match.teamB.odd && (
              <>
                <OddBtn
                  provider={provider}
                  // href={match.teamA?.link}
                  href={provider.PROVIDER_URL}
                  title={`Odd para vitória da ${match.teamA.name}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={() => {
                    trackWidgetClick(
                      `${match.matchId} - ${match.teamA.name} vs ${match.teamB.name}`
                    );
                  }}
                >
                  {match.teamA.odd.toFixed(2)}
                </OddBtn>
                <span>vs</span>
                <OddBtn
                  provider={provider}
                  // href={match.teamB?.link}
                  href={provider.PROVIDER_URL}
                  title={`Odd para vitória da ${match.teamB.name}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={() => {
                    trackWidgetClick(
                      `${match.matchId} - ${match.teamA.name} vs ${match.teamB.name}`
                    );
                  }}
                >
                  {match.teamB.odd.toFixed(2)}
                </OddBtn>
              </>
            )}

            {(!match.teamA?.odd || !match.teamB?.odd) && (
              <OddBtnMore
                block
                provider={provider}
                // href={match.teamA?.link}
                href={provider.PROVIDER_URL}
                target="blank"
                rel="noopener noreferrer"
              >
                ver odds
              </OddBtnMore>
            )}
            <img
              src={`https://${match.teamB.logo}`}
              width="30"
              alt={match.teamB.name}
              title={match.teamB.name}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = placeholderLogo;
              }}
            />
            <TeamName provider={provider} noNames={noNames}>{match.teamB.name.split(" ")[0]}</TeamName>
          </LiveOddMatch>
          </>
        ))}
        </OddsContainer>
        {/* <FooterContainer provider={provider}>
        APOSTE EM <span>LEAGUE OF LEGENDS</span> {" • "}
        APOSTE NO <span>CS2</span> {" • "}
        APOSTE EM <span>FUTEBOL</span> {" • "}
        APOSTE EM <span>VALORANT</span> {" • "}
        APOSTE EM <span>CASSINO</span>
        </FooterContainer> */}
    </LiveOddsContainer>
  );
};

const TeamName = styled.div`
  font-size: 11px;
  color: ${(p) => p.provider.colors.TEXT};
  padding: 0 2px;
  display: ${(p) => p.noNames ? "none" : "initial"};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  // width: 70%;
  display: flex;
  align-items: center;
  justify-content: center;
  // font-weight: bold;
  text-transform: uppercase;

  @media screen and (max-width: 768px) {
  display: none;
  }
`;

const LiveOddsContainer = styled.div`
  background: ${(p) => p.provider.colors.BACKGROUND_DARKER};
  ${(p) =>
    p.provider.BACKGROUND_IMAGE
      ? `background-image: url("${p.provider.BACKGROUND_IMAGE}")`
      : ``};
  background-size: 100% 100%;
  background-position: center center;
  background-repeat: no-repeat;
  color: ${(p) => p.provider.colors.TEXT};
  border-top: 3px solid ${(p) => p.provider.colors.ACCENT};
  border-bottom: 3px solid ${(p) => p.provider.colors.ACCENT};
  padding: 2px;
  display: flex;
  width: ${(p) => (p.fullWidth ? "100%" : "90%")};
  height: 90px;
  flex-wrap: nowrap
  align-items: center;
  justify-content: center;
  // justify-content: space-between;
  align-content: center;
  margin: 0 auto;
  position: relative;

  img {
    content-visibility: auto;
  }

  @media screen and (max-width: 768px) {
  width: 100%;
  justify-content: space-evenly;
  
  }
`;

const ProviderLogo = styled.a`
  display: flex;
  text-align: center;
  align-content: center;
  align-items: center;
  // flex-basis: 25%;
  justify-content: center;
  flex-direction: column;

  img {
    width: 120px;
  }

  @media screen and (max-width: 768px) {
    img {
      width: 140px;
    }
  }
`;

const LiveOddMatch = styled.a`
  background-color: ${(p) => p.provider.colors.BACKGROUND};
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 5px 0;
  transition: all 0.2s;
  // flex-direction: row-reverse;
  min-height: 42px;
  min-width: ${(p) => (p.fullWidth ? "0px" : "250px")};
  flex-direction: ${(p) => (p.reverse ? "row-reverse" : "row")};
  box-shadow: 0px 0px 3px ${(p) => p.provider.colors.BACKGROUND};


  &:hover {
    background-color: ${(p) => p.provider.colors.HOVER_COLOR};
  }

  span {
    color: ${(p) => p.provider.colors.ACCENT};
    font-size: 10px;
    margin: 0 2px;
  }

  img {
    max-width: 28px;
    max-height: 28px;
    height: auto;
    width: auto;
    margin: 0 3px;
    // padding: 2px;
  }

  @media screen and (max-width: 768px) {
    min-width: 150px;
    
    span {
      font-size: 14px;
      margin: 0 4px;
    }

    img {
      margin: 4px;
    }
  }
`;

const OddBtn = styled.div`
border-radius: 3px;
margin: 0;
cursor: pointer;
color: ${(p) => p.provider.colors.BACKGROUND_DARKER};
font-weight: 700;
font-size: 13px;
line-height: 26px;
padding: 0 3px;

height: 26px;
max-width: 38px;
min-width: 38px;
text-align: center;
transition: all 0.2s;

background: linear-gradient(
      143deg,
      ${(p) => p.provider.colors.ACCENT} 90%,
      transparent 91%
    )
    100% 0/20px 100% no-repeat,
  linear-gradient(
      -37deg,
      ${(p) => p.provider.colors.ACCENT} 90%,
      transparent 91%
    )
    0 0/20px 100% no-repeat,
  linear-gradient(
      ${(p) => p.provider.colors.ACCENT},
      ${(p) => p.provider.colors.ACCENT}
    )
    20px 0 / calc(100% - 30px) 100% no-repeat;

${(p) =>
  p.block &&
  `
  width: 100%;
  max-width: 100%;
  flex: 1;
  margin: 0 12px;
`}

&:hover {
  background: linear-gradient(
        143deg,
        ${(p) => p.provider.colors.BACKGROUND_DARKER} 90%,
        transparent 91%
      )
      100% 0/20px 100% no-repeat,
    linear-gradient(
        -37deg,
        ${(p) => p.provider.colors.BACKGROUND_DARKER} 90%,
        transparent 91%
      )
      0 0/20px 100% no-repeat,
    linear-gradient(
        ${(p) => p.provider.colors.BACKGROUND_DARKER},
        ${(p) => p.provider.colors.BACKGROUND_DARKER}
      )
      20px 0 / calc(100% - 30px) 100% no-repeat;
  text-shadow: none;
  color: ${(p) => p.provider.colors.TEXT};
  transition: 0.1s;
}

@media screen and (max-width: 768px) {
  line-height: 28px;
  height: 28px;
  // max-width: 38px;
  // min-width: 38px;
  font-size: 14px;
}
`;

const OddBtnMore = styled(OddBtn)`
  min-width: 60px;
`;

const Disclaimer = styled.div`
  text-align: center;
  color: ${(p) => p.provider.colors.TEXT};
  font-size: 9px;
  padding: 2px 0;

  @media screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const TitleOdd = styled.a`
  text-align: center;
  color: ${(p) => p.provider.colors.TEXT};
  font-size: 10px;
  display: flex;
  flex-direction: row;
  width: 100%;
  // justify-content: center;
  font-weight: bold;
  text-transform: uppercase;
  

  @media screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const OddsContainer = styled.div`
    display: flex;
    // flex-basis: 80%;
    align-content: center;
    align-items: center;
    justify-content: center;
    // flex-wrap: nowrap;
    width: 100%;
    gap: 4px;

    @media screen and (max-width: 768px) {
    // flex-basis: 70%;
    }
`;

const LogoContainer = styled.div`
    display: flex;
    // flex-basis: 15%;
    align-content: center;
    justify-content: center;
    align-items: center;
    padding: 0 10px;

    @media screen and (max-width: 768px) {
    // flex-basis: 25%;
    }
    `;

const LineBreak = styled.div`
  width: 100%;
  `;

  const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  // padding: 4px 0 0 0;
  margin-top: 4px;
  margin-bottom: -8px;
  font-size: 9px;
  place-items: center;
  gap: 4px;
  font-weight: 300;
  color: ${(p) => p.provider.colors.TEXT_LIGHT};
  
  span {
    color: ${(p) => p.provider.colors.TEXT_ACCENT};
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;



export default GenericLiveOddsLeaderboardNew;